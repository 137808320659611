import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby"

import BigLink from '../components/BigLink'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
	<main>
		<header className="text-5xl font-bold">
			<h1 className="leading-normal text-black">
				Hello 👋! I'm <span className="text-blue-400">Andrew Fitzgerald</span>. I'm a <span className="text-green-500">Creative</span>.
				I make <span className="text-orange-500">Movies</span> that you can watch <span className="text-purple-500"><Link to="/movies">Here</Link></span>. Let's work together, <span className="text-blue-500"><a href="https://www.linkedin.com/in/andrew-fitzgerald-aa34811b5/">Connect</a></span> with me.
			</h1>
	</header>
	<section className="flex flex-col justify-around py-16 md:flex-row">
		<BigLink to="">Projects</BigLink>
		<BigLink to="">LinkedIn</BigLink>
		<BigLink to="">CV</BigLink>
	</section>
	</main>
  </Layout>
)

export default IndexPage
